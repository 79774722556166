import { CircularProgress } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

export default () => (
  <LoadingContainer>
    <StyledCircularProgress size={75} />
  </LoadingContainer>
);

const LoadingContainer = styled.div`
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: #38d178 !important;
`;
