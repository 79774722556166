import React from 'react';
import styled from 'styled-components';
import NotFound from '../images/NotFound.png';

export default () => (
  <MainContainer className="NotFound">
    <img src={NotFound} alt="not found" />
    <h2>We couldn&apos;t find the page you&apos;re looking for.</h2>
    <a href="/">
      <h2>Click here to return to the shop!</h2>
    </a>
  </MainContainer>
);

const MainContainer = styled.div`
  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;

  h2 {
    margin-top: 30px;
    font-weight: 500;
    max-width: 600px;
  }

  img {
    max-width: 500px;

    @media (max-width: 300px) {
      width: 95vw;
    }
  }

  a {
    margin-top: 25px;
    color: #263238;
  }
`;
