import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';

import { useCollection } from '../context/collection';

const ModalCountdown = (props) => {
  const { date } = props;
  const { accentColor } = useCollection();

  const renderer = ({ days, hours, minutes, seconds }) => (
    <div className="modal-countdown__main" style={{ color: accentColor }}>
      <span>
        {zeroPad(days)}
        <br />
        <span className="modal-countdown__time-description">days</span>
      </span>
      <span>:</span>
      <span>
        {zeroPad(hours)}
        <br />
        <span className="modal-countdown__time-description">hours</span>
      </span>
      <span>:</span>
      <span>
        {zeroPad(minutes)}
        <br />
        <span className="modal-countdown__time-description">mins</span>
      </span>
      <span>:</span>
      <span>
        {zeroPad(seconds)}
        <br />
        <span className="modal-countdown__time-description">secs</span>
      </span>
    </div>
  );

  return <Countdown date={date} renderer={renderer} />;
};

export default ModalCountdown;
