import React from 'react';
import Countdown from 'react-countdown';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

import { useCollection } from '../context/collection';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.contrastText
  },
  countdownContainer: {
    backgroundColor: theme.palette.primary.contrastText
  }
}));

const GridCountdown = (props) => {
  const classes = useStyles();
  const { date } = props;
  const { accentColor } = useCollection();

  const getCountdownString = (days, hours, minutes, seconds) => {
    if (days) return `${days} day${days === 1 ? '' : 's'}`;
    if (hours) return `${hours} hour${hours === 1 ? '' : 's'}`;
    if (minutes) return `${minutes} minute${minutes === 1 ? '' : 's'}`;
    return `${seconds} second${seconds === 1 ? '' : 's'}`;
  };

  const renderer = ({ days, hours, minutes, seconds }) => (
    <div
      className="grid-countdown__main"
      style={{ backgroundColor: accentColor }}
    >
      <span className={classes.title}>Limited </span>
      <div
        className={clsx(classes.countdownContainer, 'grid-countdown__timer')}
      >
        <span style={{ color: accentColor }}>
          {getCountdownString(days, hours, minutes, seconds)}
        </span>
      </div>
    </div>
  );
  return <Countdown date={date} renderer={renderer} />;
};

export default GridCountdown;
