import React from 'react';
import styled from 'styled-components';
import Error from '../images/Error.png';

export default () => (
  <MainContainer className="Error">
    <img src={Error} alt="error" />
    <h2>
      Sorry! Something went wrong, <a href="/">click here</a> to try again.
    </h2>
    <h2>
      If you&apos;re seeing this page for the second time, please try again
      later or on a different device.
    </h2>
  </MainContainer>
);

const MainContainer = styled.div`
  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;

  h2 {
    margin-top: 40px;
    font-weight: 500;
    max-width: 600px;
  }

  img {
    max-width: 300px;

    @media (max-width: 300px) {
      width: 95vw;
    }
  }

  a {
    margin-top: 25px;
    color: #263238;
  }
`;
